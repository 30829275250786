import React, {useEffect} from 'react';

function Contribute(props) {
  useEffect(()=>{
    window.location.replace('https://forms.gle/ySAcTQai2cwsURqy6');
  });

  return (
    <div></div>
  );
}

export default Contribute;
